html, body, #app, #app>div,#root{
  min-height: 100%;
  height:100%;
  width: 100%;
  margin:0;
  background: rgb(40,62,86);
  background: radial-gradient(circle, rgba(40,62,86,1) 43%, rgba(5,28,51,1) 61%);
}

.users {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.user {
  padding: 2rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.calendar {
  margin: 8px;
  font-weight: 400;
  font-size: 15px;
  font-family: Arial;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
#edit-mail-section{
  width: 45%;
  border:2px solid black;
  margin-left:10px;
}
.DraftEditor-root{
  width:100%;
  min-height: 250px;
  background:white;
}
.public-DraftEditor-content{
  min-height: 250px;
}
.template-name{
  padding:0;
  width:100%;
  background-color: #00172B;
  color: white;
  text-align: center;
  border:none;

}
@media only screen and (max-width: 320px) {
  html, body, #app, #app>div,#root {
    height:100%;
    width: 100%;
    margin:0;
  }
  main{
    height: auto;
  }
}


/*
  CSS for fitting table into 100% viewport width (screen wihout scrolling)
*/

#root main section:nth-of-type(2) {
  padding: 20px 0px !important;
  max-width: 100vw !important;
}

.administration-table .MuiTableCell-alignCenter {
  font-size: 0.95rem !important;
}

.administration-cell:nth-of-type(1), .administration-cell:nth-of-type(2) {
  max-width: 35px !important;
}

.administration-cell:nth-of-type(4) {
  max-width: 90px !important;
}

.administration-table .MuiTableRow-root .MuiTableCell-root:nth-of-type(4) input {
  max-width: 70px !important;
}
.administration-cell:nth-of-type(11) {
  max-width: 40px !important;
}

.administration-table .MuiTableCell-root {
  padding: 16px 5px !important;
}



.donators-table .MuiTableCell-alignCenter {
  font-size: 0.96rem !important;
}

.donators-cell:nth-of-type(1), .donators-cell:nth-of-type(2) {
  max-width: 35px !important;
}

.donators-cell:nth-of-type(4){
  max-width: 90px !important;
}
.donators-cell:nth-of-type(4) input, .donators-cell:nth-of-type(10) input {
  max-width: 80px !important;
}

.donators-table .MuiTableCell-root {
  padding: 16px 10px !important;
}

#root main section:nth-of-type(3) {
  padding: 20px 0px !important;
}

.send-mail-table .react-datepicker__input-container {
  max-width: 100px !important;
}

.send-mail-table .MuiTableCell-alignCenter {
  font-size: 0.95rem !important;
}

.send-mail-table .MuiTableCell-alignCenter input{
  max-width: 90px !important;
}

.send-mail-cell:nth-of-type(1), .send-mail-cell:nth-of-type(6) input {
  max-width: 90px !important;
}

.send-mail-cell:nth-of-type(5) {
  max-width: 135px !important;
}

.send-mail-cell:nth-of-type(2) {
  max-width: 35px !important;
}

.send-mail-cell:nth-of-type(4), .send-mail-cell:nth-of-type(6) {
  max-width: 100px !important;
}

.send-mail-cell:nth-of-type(4) input, .send-mail-cell:nth-of-type(7) input {
  max-width: 80px !important;
}

.send-mail-cell:nth-of-type(7) {
  padding: 16px 5px !important;
  max-width: 110px !important;
}

.send-mail-cell:nth-of-type(8){
  padding: 16px 5px !important;
}

.send-mail-table .MuiTableCell-root {
  padding: 16px 10px !important;
}


#root main section:nth-of-type(1) {
  padding: 20px 0px !important;
}

.mail-history-table table tbody tr td {
  font-size: 1rem;
}

.mail-history-cell:nth-of-type(2){
  max-width: 100px !important;
}
.mail-history-table .MuiTableRow-root .MuiTableCell-root:nth-of-type(2) input, .mail-history-table .MuiTableRow-root .MuiTableCell-root:nth-of-type(5) input,
 .mail-history-table .MuiTableRow-root .MuiTableCell-root:nth-of-type(6) input, .mail-history-table .MuiTableRow-root .MuiTableCell-root:nth-of-type(9), .mail-history-table .MuiTableRow-root .MuiTableCell-root:nth-of-type(9) select{
  max-width: 80px !important;
}

.reportTable tbody tr td {
  font-size: 1.05rem !important;
}


/* Editor in ManageMailTemplates*/

.public-DraftStyleDefault-block { 
  margin: 0 !important;
}
.rdw-editor-toolbar { 
  margin-bottom: 0 !important;
}